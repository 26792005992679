
<template>
  <v-container>
    <div v-if="!isLogin">
      <v-card>
        <v-card-subtitle>检测到当前没有绑定数据，请先输入ID</v-card-subtitle>
        <v-card-text>
          <v-text-field
            v-model="userId"
            @keydown.enter="login()"
            label="ID编码"
            placeholder="回车跳转"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </div>

    <div v-if="isLogin">
      <v-card>
        <v-card-title class="indigo white--text text-h6">
          剧情框
          <v-row align="center" justify="end">
            <span class="subheading mr-2">玩家ID: {{ userId }} </span>
            <span class="subheading mr-2">事件ID: {{ currentEventId }}</span>
          </v-row></v-card-title
        >

        <v-textarea
          v-model="textContent"
          filled
          auto-grow
          readonly
          hide-details
          background-color="white"
        >
        </v-textarea>

        <div>
          <v-btn
            v-for="item in itemButtonList"
            :key="item.id"
            class="itemButtonBox"
            @click="
              queryItemEvent(item.eventId);
              queryItemContent(item.eventId);
            "
          >
            [{{ item.eventId }}] {{ item.title }}
          </v-btn>
        </div>
      </v-card>
      <v-text-field
        v-model="jumpEventId"
        @keydown.enter="jumpEvent()"
        label="跳转到事件ID页"
        placeholder="回车跳转"
      ></v-text-field>

      <v-card>
        <v-card-text>
          <v-text-field
            v-model="eventForm.title"
            label="标题"
            required
          ></v-text-field>

          <v-textarea
            v-model="eventForm.content"
            label="内容"
            :rows="4"
            required
          ></v-textarea>
          <v-btn
            color="info"
            class="itemButtonBox"
            @click="submitEvent()"
            min-width="150"
          >
            为该事件添加一个选项
          </v-btn>

          <v-btn
            color="error"
            class="itemButtonBox"
            @click="deleteEventNode()"
            min-width="150"
          >
            删除当前事件
          </v-btn>
          <v-subheader
            >注意：如果已经构建成一个环，请使用下方的删除事件</v-subheader
          >
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-title>[增加/移除]连线</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="nextEventId"
            label="事件ID"
            required
          ></v-text-field>
          <v-btn
            color="info"
            class="itemButtonBox"
            @click="addEventRel()"
            min-width="150"
          >
            增加
          </v-btn>

          <v-btn
            color="info"
            class="itemButtonBox"
            @click="deleteEventRel()"
            min-width="150"
          >
            删除
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<style scoped>
.itemButtonBox {
  margin: 10px;
}
</style>

<script>
import Monitor from "./monitor";
export default Monitor;
</script>
