import Axios from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component({
  components: {

  }
})
export default class Monitor extends Vue {

  textContent = '';
  itemButtonList = [];
  currentEventId = 1;
  jumpEventId = 1;
  nextEventId = '';

  userId = '';

  isLogin = false;

  eventForm = {
    content: '',
    previousEventId: 0,
    title: '',
    firstNode: false
  }


  mounted() {
    this.loginCheck();
    this.loadEventId();
  }

  queryAllFirstNode() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/game/event/node/first',
      method: 'get'
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.textContent = response.data.data[0].content;
        _this.currentEventId = response.data.data[0].eventId;
        _this.queryItemEvent(response.data.data[0].eventId);
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  queryItemEvent(eventId: number) {
    const _this = this;
    _this.$axios({
      url: '/api/v1/game/event/node/next',
      method: 'post',
      data: {
        id: eventId
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.itemButtonList = response.data.data;
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  queryItemContent(eventId: number) {
    const _this = this;
    _this.$axios({
      url: '/api/v1/game/event/node/info',
      method: 'post',
      data: {
        eventId: eventId
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.textContent = response.data.data.content;
        _this.currentEventId = response.data.data.eventId;
      } else {
        _this.$toast.error(response.data.message);
        _this.jumpEventId = 1;
        _this.jumpEvent();
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  jumpEvent() {
    this.queryItemEvent(this.jumpEventId);
    this.queryItemContent(this.jumpEventId);
    this.currentEventId = this.jumpEventId;
  }

  submitEvent() {
    const _this = this;
    _this.eventForm.previousEventId = _this.currentEventId;
    _this.$axios({
      url: '/api/v1/game/event/node',
      method: 'post',
      data: _this.eventForm
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.jumpEventId = _this.currentEventId;
        _this.jumpEvent();
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  deleteEventNode() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/game/event/node',
      method: 'delete',
      data: {
        id: this.currentEventId
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.jumpEventId = _this.currentEventId - 1;
        _this.jumpEvent();
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  addEventRel() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/game/event/node/rel',
      method: 'post',
      data: {
        eventId: this.currentEventId,
        nextEventId: this.nextEventId
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.jumpEventId = _this.currentEventId - 1;
        _this.jumpEvent();
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  deleteEventRel() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/game/event/node/rel',
      method: 'delete',
      data: {
        eventId: this.currentEventId,
        nextEventId: this.nextEventId
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.jumpEventId = _this.currentEventId - 1;
        _this.jumpEvent();
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  loginCheck() {
    const id = localStorage.getItem('id');
    if (id == null || id == undefined) {
      this.userId = '';
    } else {
      this.userId = id;
    }
    this.isLogin = !(id == null || id == undefined);
  }

  login() {
    localStorage.setItem('id', this.userId);
    this.loginCheck();
  }

  @Watch('currentEventId')
  changeEventId(currentEventId: number) {
    localStorage.setItem('currentEventId', currentEventId + '');
  }

  loadEventId() {
    const id = localStorage.getItem('currentEventId');
    if (id == null || id == undefined) {
      this.jumpEventId = 1;
      this.currentEventId = 1;
    } else {
      this.jumpEventId = parseInt(id);
      this.currentEventId = parseInt(id);
    }
    this.jumpEvent();
  }
}


